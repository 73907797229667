import { ref } from 'vue'
import { ElLoading, ElMessage } from 'element-plus'
import router from '@/router'
import axios, { InternalAxiosRequestConfig, AxiosResponse } from 'axios'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 10000,
})

// 打开Loading
var loading: ReturnType<typeof ElLoading.service>
function openLoading() {
  loading = ElLoading.service({
    lock: true,
    text: 'Loading',
    background: 'rgba(0, 0, 0, 0.7)',
  })
}
// 关闭Loading
function closeLoading() {
  setTimeout(() => {
    loading.close()
  }, 900)
}

// 扩展 InternalAxiosRequestConfig 以包含你的自定义配置
interface CustomAxiosRequestConfig extends InternalAxiosRequestConfig {
  config?: any
}

// 请求拦截器
service.interceptors.request.use(
  (config: CustomAxiosRequestConfig) => {
    // openLoading()
    if (config.config.showLoading == true) {
      openLoading()
    }

    // 添加请求头等前置处理
    config.headers['Authorization'] = localStorage.getItem('token')
    config.headers['Usermainid'] = sessionStorage.getItem('manageUserId')

    return config
  },
  (error) => {
    // 请求错误处理
    console.log('Request Error:', error)
    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  (response: AxiosResponse) => {
    closeLoading()

    // 响应后处理
    if (
      (response.status === 200 && response.data.code === 200) ||
      (response.status === 200 && response.config.responseType == 'blob')
    ) {
      // if (response.status === 200) {
      // return Promise.resolve(response.data)

      // 判断文件类型返回数据和文件名，非文件类型返回数据
      if (response.config.responseType == 'blob') {
        return Promise.resolve(response)
      } else {
        return Promise.resolve(response.data)
      }
    } else {
      // token过期
      if (response.data.code == 411 && response.data.msg == 'token已过期') {
        ElMessage({
          message: '登录过期，请重新登录！',
          type: 'warning',
        })
        sessionStorage.clear()
        router.replace('/login')
      } else if (response.data.code == 10102) {
        ElMessage({
          message: response.data.msg,
          type: 'warning',
        })
        sessionStorage.clear()
        router.replace('/login')
      }
      return Promise.reject(response.data)
    }
  },
  (error) => {
    // console.log('Response Error:', error)
    return Promise.reject(error)
  }
)

export default service
